<template>
  <div id="app">
    <div>
      <div>{{ !signIn ? '未登录' : nowUIStatusTxt }}</div>
      <div v-if="signIn">{{ statusTime | filterTime }}</div>
    </div>
    <div style="padding: 20px;width: 40%">
      <div style="flex: 3;justify-content: space-between;display: flex; margin-bottom: 40px">
        <Button type="primary" @click="onClick('SignIn')" size="small">{{ signInDesc }}</button>
        <Button :disabled="btn1" type="primary" @click="onClick('SetBusy')" size="small">示忙</button>
        <Button :disabled="btn2" type="primary" @click="onClick('SetIdle')" size="small">示闲</button>
        <Button :disabled="btn3" type="primary" @click="onClick('MakeCall')" size="small">呼出</button>
        <Button :disabled="btn4" type="primary" @click="onClick('Hold')" size="small">保持</button>
        <Button :disabled="btn5" type="primary" @click="onClick('RetrieveHold')" size="small">接回</button>
        <Button :disabled="btn6" type="primary" @click="onClick('Disconnect')" size="small">挂断</button>
        <Button :disabled="btn8" type="primary" @click="onClick('Conference')" size="small">会议</button>
        <Button :disabled="btn9" type="primary" @click="onClick('Answer')" size="small">应答</button>
        <Button :disabled="btn10" type="primary" @click="onClick('TransferOut')" size="small">转出</button>
        <Button :disabled="btn11" type="primary" @click="onClick('Consult')" size="small">咨询</button>
      </div>
      <div style="flex: 1;margin-right: 20px;">


        <Form :model="initInfo" label-position="left" :label-width="100">
          <FormItem label="服务器环境">
            <Input v-model="initInfo.envName" @on-change="onChangeInput('envName')"></Input>
          </FormItem>
          <FormItem label="工号">
            <Input v-model="initInfo.agentCode" @on-change="onChangeInput('agentCode')"></Input>
          </FormItem>
          <FormItem label="座席密码">
            <Input v-model="initInfo.password" @on-change="onChangeInput('password')"></Input>
          </FormItem>
        </Form>
      </div>
    </div>

    <Modal
        v-model="makeCallModal"
        title="外呼"
        @on-ok="ok"
        @on-cancel="cancel">
      <Input v-model="telPhone" placeholder="输入号码" style="width: 300px;margin-bottom: 20px"/>
      <Input v-model="displayNum" placeholder="外显号码" style="width: 300px;margin-bottom: 20px"/>
      <Select v-model="av" style="width: 300px;margin-bottom: 20px">
        <Option :value="1" :key="1">音频</Option>
        <Option :value="2" :key="2">视频</Option>
      </Select>
    </Modal>

    <Modal
        v-model="consultModal"
        title="咨询"
        @on-ok="consultOk"
        @on-cancel="cancel">
      <Input v-model="consultNum" placeholder="输入号码" style="width: 300px;margin-bottom: 20px"/>
      <Select v-model="consultType" style="width: 300px;margin-bottom: 20px">
        <Option :value="0" :key="0">座席工号</Option>
        <Option :value="1" :key="1">外部号码</Option>
        <Option :value="2" :key="2">服务号</Option>
      </Select>
    </Modal>
    <Modal
        v-model="transferOutModal"
        title="转出"
        @on-ok="transferOutOk"
        @on-cancel="cancel">
      <Input v-model="transferOutNum" placeholder="输入号码" style="width: 300px;margin-bottom: 20px"/>
      <Select v-model="transferOutType" style="width: 300px;margin-bottom: 20px">
        <Option :value="0" :key="0">座席工号</Option>
        <Option :value="1" :key="1">外部号码</Option>
        <Option :value="2" :key="2">服务号</Option>
      </Select>
    </Modal>
    <VideoAndShare ref="videoAndShare"></VideoAndShare>
  </div>
</template>

<script>
import VideoAndShare from './videoAndShare';

export default {
  name: 'App',
  components: {
    VideoAndShare
  },
  data() {
    return {
      initInfo: {
        envName: '',
        agentCode: '',
        password: '',
      },
      load: false,
      makeCallModal: false,
      consultModal: false,
      transferOutModal: false,
      telPhone: '',
      consultNum: '',
      consultType: 1,
      transferOutNum: '',
      transferOutType: 1,
      displayNum: '',
      av: 1,
      nowUIStatusTxt: '',
      statusTime: 0,
      statusTimer: null,//状态切换计时 定时器
      config: {
        tenantType: "",
        ip: '',
        port: '',
        vccId: '',
        agentId: '',
        password: '',
        loginKey: '',
        event: {
          OnInitalSuccess: this._OnInitalSuccess,
          OnInitalFailure: this._OnInitalFailure,
          OnAnswerCall: this._OnAnswerCall,
          OnCallEnd: this._OnCallEnd,
          OnReportBtnStatus: this._OnReportBtnStatus,
          OnCallRing: this._OnCallRing,
          OnBarExit: this._OnBarExit,
          OnUpdateVideoWindow: this._OnUpdateVideoWindow,
          OnAgentWorkReport: this._OnAgentWorkReport
        }
      },
      VccBar: null,
      signIn: false,
      signInDesc: '未登录',
      btn1: true,//示忙
      btn2: true,//示闲
      btn3: true,//呼出
      btn4: true,//保持
      btn5: true,//接回
      btn6: true,//挂断
      btn8: true,//会议
      btn9: true,//应答
      btn10: true,//转出
      btn11: true,//咨询
    };
  },
  created() {
    // setTimeout(() => {
    //    this.initInfo= {
    //     envName: '自研呼叫中心',
    //     agentCode: '0000101000452573',
    //     password: 'f6F3Wi6B',
    //   }
    // }, 100);
  },
  methods: {
    consultOk() {
      this.VccBar.Consult(this.consultType, this.consultNum);
    },
    consultCancel() {
      this.consultModal = false;
    },
    transferOutOk() {
      this.VccBar.TransferOut(this.consultType, this.transferOutNum);
    },
    transferOutCancel() {
      this.transferOutModal = false;
    },
    _setStatusTime(type) {
      if (type == 1) {
        this.statusTime = 0;
        this.statusTimer = clearInterval(this.statusTimer);
        this._setStatusTime();
      } else {
        this.statusTimer = setTimeout(() => {
          this.statusTime++;
          this._setStatusTime();
        }, 1000);
      }
    },
    onChange(value) {
      
    },
    ok() {
      if (!this.telPhone) {
        return;
      }
      this.VccBar.MakeCall(this.telPhone, 3, '', '', '', '', '', '', this.displayNum, '', this.av);
    },
    cancel() {
      this.makeCallModal = false;
    },
    _OnInitalSuccess() {
      this.signIn = true;
      this.signInDesc = '登出';
      console.log('初始化成功');
    },
    _OnInitalFailure() {
    },

    _OnReportBtnStatus(btnIDS) {
      console.log(btnIDS);
      var arrIDS = btnIDS.split('|');
      this.setBtnDisable();
      for (var i = 0; i < arrIDS.length; i++) {
        this['btn' + parseInt(arrIDS[i])] = false;
      }
    },
    setBtnDisable() {
      this.btn1 = true;
      this.btn2 = true;
      this.btn3 = true;
      this.btn4 = true;
      this.btn5 = true;
      this.btn6 = true;
      this.btn8 = true;
      this.btn9 = true;
      this.btn10 = true;
      this.btn11 = true;
    },
    _OnCallRing(callingNo, calledNo, orgCalledNo, callData, serialID, serviceDirect, callID, userParam, taskID, userDn, agentDn, areaCode, fileName, networkInfo, queueTime, opAgentID, ringTime, projectID, accessCode, taskName, cityName, userType, lastServiceId, lastServiceName, accessNumber) {

    },
    _OnAnswerCall(userNo, answerTime, serialID, serviceDirect, callID, userParam, taskID, av, tc, haveAsrEvent) {
      if (av === 'video') {
        //这里控制视频窗口显示
        this.$refs['videoAndShare'].isVideo = true;
      }
    },
    _OnCallEnd(callID, serialID, serviceDirect, userNo, bgnTime, endTime, agentAlertTime, userAlertTime, fileName, directory, disconnectType, userParam, taskID, serverName, networkInfo) {
      console.log('通话结束');
    },
    _OnBarExit(code, description) {
      this.signInDesc = '未登录';
      this.signIn = false;
      console.log('坐席迁出');
    },
    _OnUpdateVideoWindow(param) {
      this.$refs.videoAndShare.onUpdateVideoWindow(param)
    },
    _OnAgentWorkReport(workStatus, description) {
      this.nowUIStatusTxt = description
      this._setStatusTime(1);
    },
    onClick(name) {
      switch (name) {
        case 'SignIn':
          this.handleSignIn();
          break;
        case 'SetIdle':
          this.VccBar.SetIdle();
          break;
        case 'SetBusy':
          this.VccBar.SetBusy(0);
          break;
        case 'MakeCall':
          this.makeCallModal = true;
          break;
        case 'Answer':
          this.VccBar.Answer();
          break;
        case 'Disconnect':
          this.VccBar.Disconnect();
          break;
        case 'RetrieveHold':
          this.VccBar.RetrieveHold();
          break;
        case 'Hold':
          this.VccBar.Hold();
          break;
        case 'Consult':
          this.consultModal = true;
          break;
        case 'Conference':
          this.VccBar.Conference()
          break;
        case 'TransferOut':
          this.transferOutModal = true;
          break;
      }
    },
    onChangeInput(name) {
      switch (name) {
        case 'envName':
          if (this.initInfo.envName === '自研呼叫中心') {
            this.config.ip = 'iccs.pointlinkprox.com';
            this.config.port = '9080';
            this.config.loginKey = '3W4SS2MK1YJBBJHWQEWOSRFF';
            this.config.tenantType = 1;
          }
          else if (this.initInfo.envName === '移动云呼叫中心') {
            this.config.ip = 'iccs.pointlinkprox.com';
            this.config.port = '5049';
            this.config.loginKey = '';
            this.config.tenantType = 0;
          }
          else if (this.initInfo.envName === '淮安呼叫中心') {
            this.config.ip = 'cinccagentlogin.deskpro.cn';
            this.config.port = '5049';
            this.config.loginKey = '';
            this.config.tenantType = 0;
          }
          else if (this.initInfo.envName === '洛阳呼叫中心'){
            this.config.ip = 'www.deskpro.cn';
            this.config.port = '5049';
            this.config.loginKey = '';
            this.config.tenantType = 0;
          }
          else {
            this.config.ip = '';
            this.config.port = '';
            this.config.loginKey = '';
            this.config.tenantType = '';
          }
          this.load = false;
          break;
        case 'agentCode':
          this.config.vccId = this.initInfo.agentCode.slice(6, this.initInfo.agentCode.length - 4);
          this.config.agentId = this.initInfo.agentCode.slice(this.initInfo.agentCode.length - 4);
          break;
        case 'password':
          this.config.password = this.initInfo.password;
          break;

      }
    },
    handleSignIn() {
      if (this.signIn) {
        this.VccBar.UnInitial();
      } 
      else {
        // 如果已经加载过cmscVccBar，不再重新加载
        if (!this.load){
          this.VccBar =VccBar.setConfig(this.config).client();
          this.VccBar.load('cmscVccBar').then(() => {
            this.load = true;
            this.VccBar.Initial();
          });
        }
        else {
          this.VccBar.Initial();
        }
      }
    }
  },
  filters: {
    filterTime(val) {
      let h = parseInt(val / 3600);
      h = h > 9 ? `${h}` : `0${h}`;
      let m = parseInt((val - 3600 * h) / 60);
      m = m > 9 ? `${m}` : `0${m}`;
      let s = val - 3600 * h - 60 * m;
      s = s > 9 ? `${s}` : `0${s}`;
      return `${h}:${m}:${s}`;
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
